<attendance-page-header></attendance-page-header>
<div class="app-page">
    <div class="container-fluid full-width">
      <div class="administration-view center-dash">
        <div class="entity-detail event border-bottom">
          <h4 class="name">
            <span class="item-id labeled">Event Id<span class="number">{{eventId}}</span></span>
            {{courseName}}
          </h4>
          <ul class="styled-list title-list">
            <li>
              <span class="info">
                <i class="fa fa-calendar fa-light" aria-hidden="true"></i>Event Date<span>{{startDate+endDate}}</span>
              </span>
            </li>
          </ul>
        </div>
        <div class="attendance-section">
          <h2>Attendance Verification</h2>
          <div class="row">
            <div class="col-md-4 col-md-push-8">
              <attendance-add [event]="event" [isOnline]="isOnline" [attendanceForm]=attendanceForm (refreshList)="refreshListAfterAdd($event)"
                              (verifyAttendee)="verifyAttendee($event)"></attendance-add>
            </div>
            <div [ngClass]="{'col-md-8 col-md-pull-4': isOnline, 'col-md-12': !isOnline}">
              <attendance-list [refresh]="refresh" [verifyAttendeeEvent]="verifyAttendeeEvent" [eventId]="eventId"
                               [attendanceForm]=attendanceForm [isOnline]="isOnline"></attendance-list>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
