<event-page-header (toggleShowFilter)="eventSearchForm.toggleFilterSlider()" [toggleShowFilterDisabled]="eventSearchForm.isFilterSliderOpen"></event-page-header>
<div class="app-page">
  <div class="event-attendance administration-view">
    <div class="section app-search events">
      <div class="container-fluid full-width">
        <div class="row">
          <div class="col-md-4 col-lg-3 side-search">
            <app-event-search-form [eventSearchForm]=eventSearchForm [showSearchForm]=eventSearchForm.isFilterSliderOpen (toggleShowFilter)="eventSearchForm.toggleFilterSlider()"></app-event-search-form>
          </div>

          <div class="col-md-8 col-lg-9">
            <app-event-search-list [eventSearchForm]=eventSearchForm class="col-md-8 col-lg-9 col-xs-12 "></app-event-search-list>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
