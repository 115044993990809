import { FormControl } from "@angular/forms";
import { Injectable } from "@angular/core";
import { BaseSearchForm } from "../../shared/forms/base-search.form";
import { RegistryApiService } from "../../shared/service/registry-api.service";
import { PageResult } from "../../shared/models/page-result.model";
import { Event } from "../../shared/models/event.model"; 
import { smartLabelEnum } from "../../shared/enums/smart-label-enum";

@Injectable({ providedIn: 'root' })
export class EventSearchForm extends BaseSearchForm<Event> {

  public previousDaysPastEvent: boolean;

  public controls = {
    courseName: new FormControl<string>(''),
    eventId: new FormControl<number | null>(null),
    daysPastEvent: new FormControl<boolean>(false),
    daysFutureEvent: new FormControl<boolean>(false),
    pageNumber: new FormControl<number>(this.currentPage),
    pageSize: new FormControl<number>(this.pageSize),
    
  };

  
  constructor(
    private registryApiService: RegistryApiService
  ) {
    super(true);
    this.setupBaseFormNoReset();
    this.controls.courseName.setValue(this.getFromSessionStorage('eventSearchCourseName') || '');
    if (sessionStorage.getItem('eventSearchEventId') !== null) {
      this.controls.eventId.setValue(parseInt(this.getFromSessionStorage('eventSearchEventId')!));
    }
    this.controls.daysPastEvent.setValue(this.getFromSessionStorage('eventSearchdaysPastEvent') === 'true');
    this.controls.daysFutureEvent.setValue(this.getFromSessionStorage('eventSearchdaysFutureEvent') === 'true');
    this.previousDaysPastEvent = this.controls.daysPastEvent.value!;
    this.search();
  };

  protected override fetchServiceFunction(params: { [key: string]: unknown; }): Promise<PageResult<Event>> {
    return this.registryApiService.getEvents(params);
  };

  public override search(): Promise<void>  {
    this.previousDaysPastEvent = this.controls.daysPastEvent.value!;
    return super.search();
  };

  private getFromSessionStorage(key: string): string|null  {
    var ret: string | null = sessionStorage.getItem(key);
    if (ret!=null) {
          sessionStorage.removeItem(key);
    }
    return ret;
  };
}
