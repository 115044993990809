<section class="AppNav navbar-fixed-top">
    <div class="navbar navbar-default" role="navigation">
        <div class="container-fluid">

            <div class="navbar-header">
                <a class="navbar-brands">
                    <img nwnCdnClient="header-logo.png" alt="main logo image">
                </a>
            </div>
            <ul class="nav navbar-nav navbar-right">
                <li>
                    <a id="roledropdown" href="#" class="dropdown-toggle" (click)="toggleDropdown($event)" role="button"
                        aria-expanded="false">
                        <span>
                            <i aria-hidden="true" role="presentation" class="fa fa-user-circle-o fa-fw"></i>
                            <span class="context-name">{{name |async}} </span>
                            <i aria-hidden="true" role="presentation" class="fa fa-caret-down"></i>
                        </span>
                    </a>
                    <ul class="dropdown-menu" [class.show]="dropdownOpen" role="menu">
                        <li><a id="profilelink" href="javascript:void(0);"><i aria-hidden="true" role="presentation"
                                    class="fa fa-user-circle-o fa-fw"></i>{{name|async}} </a></li>

                        <li id="btnLogout">
                            <a class="logout" (click)="onLogoutButtonClicked()" href="javascript:void(0);">
                                <i aria-hidden="true" role="presentation" class="fa fa-sign-out fa-fw"></i>{{ 'Logout'
                                }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div class="bg-danger offline-header" *ngIf="!isOnline">
        <i class="fa fa-solid fa-signal-slash" aria-hidden="true"></i> Application Offline
    </div>
</section>

<main class="content-box" [ngClass]="{'offline': !isOnline}">

    <!-- Main Page Content Outlet -->

    <router-outlet></router-outlet>


</main>
<footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <p class="footer-logo"><img nwnCdnClient="footer-logo.png" alt="footer logo image" /></p>

                &copy; Copyright
                <script type="text/javascript">
                    <!--
                                            var currentTime = new Date()
                    
                                            var year = currentTime.getFullYear()
                                            document.write(year)
                                            //-->
                </script>. All rights reserved.
            </div>
            <div class="col-md-6 right-align-txt col-sm-6">
                <div class="footer-logo"><img nwnCdnClient="insight-footer-logo.png" alt="Footer logo" /></div>
            </div>
        </div>
    </div>
</footer>
