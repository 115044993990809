export enum InvoiceStatusEnum {
  Deleted = -3,
  Cart = -2,
  Pending = -1,
  Unpaid = 0,
  PayPal = 1,
  Paid = 2,
  Waived = 3,
  Cancelled = 4
}
