import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, interval, takeUntil } from "rxjs";
import { RegistryApiService } from "./registry-api.service";
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class OfflineService implements OnDestroy {
    private unsubscribe = new Subject<void>();
    private _eventUsersRegisteredOffline = new Map<number, Array<number>>();
    
    private readonly intervarlOfflineCheckMS = 2000;
    private onlineStatus: boolean = true;
    private _isOnline$ = new BehaviorSubject<boolean>(this.onlineStatus);

    public isOnline$ = this._isOnline$.asObservable();

    constructor(private registryApiService: RegistryApiService) {        
        interval(this.intervarlOfflineCheckMS)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
            var checkOnlineStatus = window.navigator.onLine;

            if (checkOnlineStatus !== this.onlineStatus) {
                // Set the new status and propigate change
                this.onlineStatus = checkOnlineStatus;
                this._isOnline$.next(this.onlineStatus);

                // If offline status changed and is true (back online) then reprocess any offline attendees
                if (this.onlineStatus) {
                    this.reprocessOfflineAttendees();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public getOfflineUsersForEvent(eventId: number): Array<number> | undefined {
        return this._eventUsersRegisteredOffline.get(eventId);
    }
    public setOfflineUserForEvent(userData: RegisterOfflineUser) {
        var existingUsers = this._eventUsersRegisteredOffline.get(userData.eventId);

        this._eventUsersRegisteredOffline.set(userData.eventId, !!existingUsers ? [...existingUsers, userData.userId] : [userData.userId]);
    }

    private reprocessOfflineAttendees() {
        var reprocessOffline: any[] = [];
        this._eventUsersRegisteredOffline.forEach((value, key) => {
            value.forEach((attendeeId: number) => {
                reprocessOffline.push(this.registryApiService.verifyAttendee(attendeeId, key));
            });
        });

        // Ensure we have records to process
        if (reprocessOffline.length > 0) {
            Promise.all(reprocessOffline)
                .then(async (result: Array<boolean>) => {
                    const allProcessedSuccesfully = result.every(x => x === true);

                    Swal.fire({
                        title: 'Offline Attendance Verified:',
                        html: allProcessedSuccesfully ? 
                            'Selected attendees when offline have been verified.' : 
                            'Selected attendess when offline have been processed and one or more have encounted an issue. Please re-try registration.',
                        icon: 'info',
                        timer: 3000,
                        timerProgressBar: allProcessedSuccesfully
                    });
        
                    // All offline records process, clear the collection
                    this._eventUsersRegisteredOffline.clear();
                    reprocessOffline = [];
                })
                .catch(() => {
                    Swal.fire({
                        title: 'ERROR',
                        text: 'The offline attendees could Not be verified!',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                });
        }
    }
}

export interface RegisterOfflineUser {
    eventId: number;
    userId: number;
}