import { Component, Input, output } from '@angular/core';
import { Attendee } from '../../shared/models/attendee.model';
import { ModalComponent } from '../../shared/components/modal/modal.component';
import { InvoiceStatusEnum } from '../../shared/enums/invoiceU-staus.enum';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'attendance-verify-modal',
  standalone: true,
  imports: [ModalComponent,CommonModule],
  templateUrl: './attendance-verify-modal.component.html',
})
export class AttendanceVerifyModalComponent {
  
  @Input({ required: true }) public attendee: Attendee = new Attendee();

  @Input({ required:true }) public registryIdLabel!: string;
 
  public exitModal = output<void>();

  public verifyAttendee = output<Attendee>();

  get hasPaid(): boolean | undefined {
    if (this.attendee?.registrationInvoiceId == null) {
      return undefined;
    }
    return this.attendee?.registrationInvoiceStatusId == InvoiceStatusEnum.Paid;
  }

  onYesClick(): void {
    this.verifyAttendee.emit(this.attendee);
  };
}
