import { CommonModule, DecimalPipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { NgxPagerComponent } from '../../shared/components/ngx-pager/ngx-pager.component';
import { PageResultCounterComponent } from '../../shared/components/ngx-pager/pageresult-counter/pageresult-counter.component';
import { smartLabelEnum } from '../../shared/enums/smart-label-enum';
import { Attendee } from '../../shared/models/attendee.model';
import { RegistryApiService } from '../../shared/service/registry-api.service';
import { AttendanceForm } from '../attendance-form/attendance-form.form';
import { AttendanceVerifyModalComponent } from '../attendance-verify-modal/attendance-verify-modal.component';
import { AttendanceListRecordComponent } from './attendance-list-record/attendance-list-record.component';
import { PageResult } from '../../shared/models/page-result.model';
import { OfflineService } from '../../shared/service/offline.service';
import { InvoiceStatusEnum } from '../../shared/enums/invoiceU-staus.enum';

@Component({
    selector: 'attendance-list',
    standalone: true,
    providers: [DecimalPipe, BsModalService, RegistryApiService],
    imports: [CommonModule, PageResultCounterComponent, NgxPagerComponent,
        AttendanceListRecordComponent, PageResultCounterComponent, AttendanceVerifyModalComponent,
    ],
    templateUrl: './attendance-list.component.html',
})
export class AttendanceListComponent implements OnInit, OnDestroy {

    @Input({ required: true }) public eventId!: number; // Define an verifyAttendeeModalRef property to receive the event ID

    @Input({ required: true }) public attendanceForm!: AttendanceForm;

    @Input({ required: true }) public refresh!: Subject<PageResult<Attendee>>;

    @Input({ required: true }) public verifyAttendeeEvent!: Subject<Attendee>;

    @Input({ required: true }) isOnline: boolean = true;

    @ViewChild('verifyModalref', { read: TemplateRef }) public verifyAttendeeModal!: TemplateRef<any>;

    public showList: boolean = true;

    public verifyingAttendee: Attendee = new Attendee();

    public registryIdLabel: string = "N/A";

    private currentModal?: BsModalRef;

    private modalConfig = {
        animated: true,
        backdrop: 'static' as 'static',
        keyboard: false
    };

    constructor(
        private modalService: BsModalService,
        private registryApiService: RegistryApiService,
        private offlineSerivce: OfflineService
    ) {
        this.registryApiService.getSmartLabel(smartLabelEnum.registryIDLabel).then((result) => { this.registryIdLabel = result });
    };

    async ngOnInit(): Promise<void> {
        this.attendanceForm?.reload(true).then(() => {
            // Check if there are offline registered users for the event and if so, flag them as registered already
            var offlineRegistered = this.offlineSerivce.getOfflineUsersForEvent(this.eventId);

            offlineRegistered?.forEach(attendeeId => {
                var _foundAttendee = this.attendanceForm.resultModels.find(x => x.appId === attendeeId);
                if (!!_foundAttendee) {
                    _foundAttendee.attended = true;
                }
            });
        });

        this.refresh.subscribe((attendeeList:PageResult<Attendee>) => {
            this.refreshListDataAfterAdd(attendeeList);
        });
        this.verifyAttendeeEvent.subscribe((attendee: Attendee) => {
            this.showVerifyModal({ attendee: attendee, modalRef: this.verifyAttendeeModal });
        });
    }

    ngOnDestroy(): void {
        this.refresh.unsubscribe();
        this.verifyAttendeeEvent.unsubscribe();
    }

    get showAlert(): boolean {
        return this.attendanceForm.totalCount === 0;
    }

    get showResultsLabel(): boolean {
        return this.attendanceForm.totalCount > 0;
    }

  paymentStatusText(attendee: Attendee): string {
     var paymentStatus: boolean | undefined;
      if (attendee?.registrationInvoiceId == null) {
        paymentStatus = undefined;
      }
    paymentStatus = attendee?.registrationInvoiceStatusId == InvoiceStatusEnum.Paid;
    if (paymentStatus === undefined) {
      return ""
    } else if (paymentStatus) {
      return "<br>Payment Statud: Paid"
    } else {
      return "<br>Payment Status: Not Paid"
    }
  }

      


    // This is called when the refresh observable is called
    refreshListDataAfterAdd(attendeeList:PageResult<Attendee>): void {
        this.showList = false;
        this.attendanceForm.resultModels = attendeeList.data;
        this.attendanceForm.totalCount = attendeeList.totalCount;
        this.showList = true;
        Swal.fire({
            title: 'Success',
            text: 'The Attendee has been added!',
            icon: 'success',
            confirmButtonText: 'Ok'
        });
    };

    async verifyAttendee(attendee: Attendee): Promise<void> {
        this.registryApiService.verifyAttendee(attendee.appId, this.eventId).then(async (result: boolean) => {
            if (result && this.attendanceForm.resultModels) {
                var index = this.attendanceForm.resultModels.findIndex((curAttendee: Attendee) => curAttendee.appId === attendee.appId)
                if (this.attendanceForm.resultModels[index] !== undefined) {
                    this.attendanceForm.resultModels[index].attended = true;
                }
                Swal.fire({
                    title: 'Attendance Verified',
                    html: `
                    The following attendee was verified:
                    <br>${this.registryIdLabel} #${attendee.appId || 'N/A'}
                    <br>${attendee.firstName} ${attendee.lastName}
                    <br>Email: ${attendee.email || "N/A"}
                    ${this.paymentStatusText(attendee)}
                    `,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                this.exitModal();
                return;
            }
            this.displayError();
        }, (error) => {
            if (!this.isOnline) {                
                // Store offline event to be processed later
                this.offlineSerivce.setOfflineUserForEvent({eventId: this.eventId, userId: attendee.appId});

                // Show offline modal
                Swal.fire({
                    title: 'Offline Attendance Verified',
                    html: `
                    The following attendee was verified and will process when connection is re-established:
                    <br>${this.registryIdLabel} #${attendee.appId || 'N/A'}
                    <br>${attendee.firstName} ${attendee.lastName}
                    <br>Email: ${attendee.email || "N/A"}
                    ${this.paymentStatusText(attendee)}
                    `,
                    icon: 'warning',
                    confirmButtonText: 'Ok'
                });

                // Flag attendee as attended and close modal
                attendee.attended = true;
                this.exitModal();
            } else {
                this.displayError();
            }
        });
    };

    private displayError(): void {
        Swal.fire({
            title: 'ERROR',
            text: 'The Attendee could Not be verified!',
            icon: 'error',
            confirmButtonText: 'Ok'
        });
        this.exitModal();
    };

    exitModal(): void {
        this.currentModal?.hide();
    };

    showVerifyModal(data: { attendee: Attendee, modalRef: TemplateRef<any> }): void {
        this.verifyingAttendee = data.attendee;
        this.openModal(data.modalRef);
    };

    openModal(modalTemplate: TemplateRef<any>) {
        this.currentModal = this.modalService.show(modalTemplate, this.modalConfig);
    };
}
