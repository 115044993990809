import { Component, Input, OnInit, output } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScannerService } from '../../shared/service/scanner.service';
import { ModalComponent } from '../../shared/components/modal/modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'attendance-add-by-scanner',
  standalone: true,
  imports: [CommonModule, ZXingScannerModule,ModalComponent],
  providers: [ScannerService],
  templateUrl: './attendance-add-by-scanner.component.html',
  styleUrl: './attendance-add-by-scanner.component.css',
})
export class AttendanceAddByScannerComponent {

  //keeping the service singleton and rendered
  @Input({required:true}) public scannerService!: ScannerService;

  @Input({ required: true }) public  eventId!: number;

  public exitModal = output<void>();

  public availableDevices: MediaDeviceInfo[]|undefined;

  public currentDevice: MediaDeviceInfo|undefined;

  public hasDevices: boolean = false;

  public currentDeviceIndex: number = 0;

  constructor() { };

  get canSwitchCamera(): boolean {
    if (!this.availableDevices) {
      return false;
    }
    return this.availableDevices && this.availableDevices.length > 1;
  }

  scanSuccess(result: string): void {
    this.scannerService.scanSuccess(this.eventId,result)
  };

  scanErrorHandler(reault:Error): void {
    Swal.fire({
      title: 'Invalid QR code',
      text: 'The QR code is invalid',
      icon: 'error',
      confirmButtonText: 'Ok'
    })
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
    this.currentDeviceIndex = this.availableDevices.length-1;
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices!.find(x => x.deviceId === selected);
    if (device && device.deviceId != this.currentDevice?.deviceId) {
      this.currentDevice = device;
    }
  }

  switchCamera(): void {
    this.currentDeviceIndex = (this.currentDeviceIndex + 1) % this.availableDevices!.length;
    if (this.availableDevices && this.currentDevice != this.availableDevices[this.currentDeviceIndex]) {
      this.currentDevice = this.availableDevices![this.currentDeviceIndex];
    }

  }

}
