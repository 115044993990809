  <base-modal>
    <div modal-header>
      <button type="button" (click)="exitModal.emit()" class="close" aria-label="Close"><span>×</span></button>
      <h4 id="confirmation_title" class="modal-title"><i class="fa fa-user-check fa-light"></i>New Attendee Confirmation</h4>
    </div>
    <div modal-body>
      <div class="confirmation-modal">
        <div class="instructions">
          Please confirm the following attendee should be added to this events roster. Their attendance will be verified as part of this process.
        </div>
        <div class="entity-detail user">
          <h4 class="name">
            <span class="item-id labeled">{{registryLabel}}<span class="number">{{attendee.appId||"N/A"}}</span></span>
            {{attendee.firstName}} {{attendee.lastName}}
          </h4>
          <ul class="styled-list title-list">
            <li>
              <span class="info">
                <i class="fa fa-light fa-envelope" aria-hidden="true"></i>
                Email<span>
                  {{attendee.email||"N/A"}}
                </span>
              </span>
            </li>
            <!--Paid Status-->
            <li *ngIf="hasPaid!==undefined && hasPaid">
              <span class="info">
                <i class="fa fa-light fa-file-invoice-dollar" aria-hidden="true"></i>Payment Status<span>Paid</span>
              </span>
            </li>
            <!--Unpaid Status-->
            <li *ngIf="hasPaid!==undefined && !hasPaid">
              <span class="info alert">
                <i class="fa fa-light fa-file-invoice-dollar" aria-hidden="true"></i>Payment Status<span>Unpaid</span>
              </span>
            </li>
          </ul>
        </div>

      </div>
      <div *ngIf="attendeeTypesExists">
        <label for="attendee_type">
          Please Select an Attendee Type
        </label>
        <select id="attendee_type" class="form-control input-lg" [(ngModel)]="selectedAttendeeType">
          <option [ngValue]="undefined" disabled selected>Select an Attendee Type</option>
          <option *ngFor="let type of attendeeTypes" [ngValue]="type">{{type.attendeeTypeName}}</option>
        </select>
      </div>
    </div>
    
    
   
    <div modal-footer>
      <div class="row">
        <div class="twobutton-col">
          <button type="button" role="button" [disabled]="attendeeTypeNotSelected" data-dismiss="modal" class="btn primary-btn btn-lg add-confirm-manual" (click)="onConfirmClick()">Add Attendee</button>
        </div>
        <div class="twobutton-col">
          <button role="button" type="button" data-dismiss="modal" class="btn btn-gray btn-lg" (click)="exitModal.emit()">Cancel</button>
        </div>
      </div>
    </div>
  </base-modal>

