import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, output } from '@angular/core';
import { Attendee } from '../../../shared/models/attendee.model';
import { InvoiceStatusEnum } from '../../../shared/enums/invoiceU-staus.enum';

@Component({
    selector: 'attendance-list-record',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './attendance-list-record.component.html',
})
export class AttendanceListRecordComponent implements OnInit {

    @Input({ required: true }) public attendee!: Attendee;

    @Input({ required: true }) public verifyAttendeeModalRef!: TemplateRef<any>;

    @Input({ required: true }) public isVerified: boolean = false;

    @Input({ required: true }) public registryIdLabel!: string;

    public showVerifyModal = output<{ attendee:Attendee, modalRef:TemplateRef<any> }>();

    public id: number | undefined;

    public email: string | undefined;

    private firstName: string | undefined;

    private lastName: string | undefined;

    constructor() { };

    get fullName(): string {
        return `${this.firstName || ''} ${this.lastName || ''}`.trim();
    };

    get hasPaid(): boolean | undefined
    {
        if (this.attendee?.registrationInvoiceId == null) {
          return undefined;
        }
        return this.attendee?.registrationInvoiceStatusId == InvoiceStatusEnum.Paid;
      }

    onVerifyClick() {
        if (!this.isVerified && this.attendee) {
            this.showVerifyModal.emit({ attendee: this.attendee, modalRef: this.verifyAttendeeModalRef });
        }
    };

  ngOnInit() { 
      this.id = this.attendee?.appId;
      this.firstName = this.attendee?.firstName
      this.lastName = this.attendee?.lastName;
      this.email = this.attendee?.email;
      this.isVerified = this.attendee?.attended!;
    };
}
