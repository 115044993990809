export class Attendee implements IAttendee {
    constructor(
    public id: number=0,
    public appEventId: number=0,
    public appId: number=0,
    public eventId: number=0,
    public firstName: string='',
    public lastName: string='',
    public email: string='N/A',
    public attended: boolean=false,
    public stale: boolean = false,
    public attendeeTypeId?: number,
    public paid?: boolean,
    public registrationInvoiceId?: number,
    public registrationInvoiceStatusId?:number,
    public trainerLevelIDRequested?: number
  ) { };

    toString(): string {
        return `${this.firstName} ${this.lastName}`;
  };
};
export interface IAttendee {
  id: number;
  appEventId: number;
  appId: number;
  eventId: number;
  attendeeTypeId?: number;
  firstName: string;
  lastName: string;
  email: string;
  attended: boolean;
  paid?: boolean;
  trainerLevelIDRequested?: number;
  stale: boolean;

  toString: () => string;
};
