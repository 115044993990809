<div class="item" *ngIf="event">
  <h5 class="name">
    <span class="item-id labeled">
      Event ID<span class="number">{{event.id}}</span>
    </span>
    {{event.title}}
  </h5>
  <div class="row">
    <div class="col-sm-9">
      <ul class="styled-list title-list">
        <li>
          <span class="info">
            <i class="fa fa-calendar fa-light" aria-hidden="true"></i>Date<span>{{startDate+endDate}}</span>
          </span>
        </li>
      </ul>
    </div>
    <div class="col-sm-3">
      <div class="app-buttons">
        <div class="btn-box right">
          <button (click)="viewEventRoster()" aria-label="click to view event attendance" type="button" role="button" class="btn primary-btn btn-icon"><i class="fa fa-users-line fa-fw fa-light" aria-hidden="true"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
